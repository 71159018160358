import { createApp } from 'vue';
import SearchForm from 'ewi-ui/components/2-molecules/SearchForm/SearchForm.vue';

const allSearchForms = document.getElementsByClassName('m-search-form');
for (let i = 0; i < allSearchForms.length; i++) {
  let mountElementDataset = allSearchForms[i].dataset;
  const app = createApp(SearchForm, {
    config: mountElementDataset,
    component_id: i,
  });
  app.mount(allSearchForms[i]);
}
